<template>
    <div>
        <v-card class="rounded-xl pa-3" v-if="trip">
            <v-img gradient="to top right, rgba(100,115,201,.0), rgba(25,32,72,.7)"
            max-height="200" class="rounded-xl" v-if="trip.image" :src="trip.image"
            >
                <div v-if="$route.path.includes('/vendors')" class="d-flex justify-end">
                    <v-switch
                    class="mx-5"
                    @change="$emit('swapStatus', trip)"
                    inset
                    v-model="trip.is_active"
                    :color="trip.is_active ? 'success' : 'error'"
                    ></v-switch>
                </div>
                <!-- <div v-if="$route.path.includes('/admin')" class="d-flex justify-end">
                    <v-switch
                    class="mx-5"
                    inset
                    v-model="trip.is_active"
                    :color="trip.is_active ? 'success' : 'error'"
                    readonly
                    ></v-switch>
                </div> -->
            </v-img>
            <v-card-title class="text-h5 text-capitalize font-weight-medium paragraph--text d-flex justify-space-between">
                {{trip.name}}
                <div v-if="$route.path.includes('/admin')" class="body-1">
                    <v-icon :color="trip.is_active ? 'success' : 'error'">{{trip.is_active ? 'mdi-check-circle' : 'mdi-close-circle'}}</v-icon>
                    {{trip.is_active ? 'Active' : 'Inactive'}}
                </div>
            </v-card-title>

            <v-card-subtitle class="my-1 py-0"><v-icon left>mdi-calendar</v-icon>{{trip.date}}</v-card-subtitle>
            <v-card-subtitle v-if="trip.city" class="text-h6"><v-icon left>mdi-map-marker</v-icon>{{trip.city}}</v-card-subtitle>
            <v-card-text class="pb-0">
                <p class="primary--text text-h6">{{trip.price_per_person | currency}}</p>
            </v-card-text>
            <v-card-actions>
                <v-btn elevation="0" x-large rounded color="primary px-5" @click="viewTripDetails(trip)">view details</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
  props: ['trip', 'agencyId'],
  methods: {
    viewTripDetails (trip) {
      this.$store.dispatch('setAgencyTrip', trip)
      if (this.$route.path.includes('/admin')) this.$router.push({ name: 'adminAgencyTripDetails', params: { agencyId: this.agencyId, id: trip.id } })
      else if (this.$route.path === '/trips') this.$router.push({ name: 'tripDetails', params: { id: trip.id } })
      else this.$router.push({ name: 'agencyTripDetails', params: { id: trip.id } })
    }
  }
}
</script>
