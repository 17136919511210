<template>
    <div>
        <div class="background py-10">
            <v-container>
                <v-row no-gutters>
                    <v-col class="pa-1" cols="12" md="5">
                        <GmapAutocomplete
                        class="google-ac round-edges"
                        @place_changed='setPlace'
                        />
                    </v-col>
                    <v-col class="pa-1" cols="12" md="5">
                        <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            :value="date ? new Date(date.replaceAll('-', '/')).toDateString() : ''"
                            persistent-hint
                            v-bind="attrs"
                            solo
                            flat
                            v-on="on"
                            placeholder="Start Date"
                            class="rounded-lg"
                            clearable
                            @change="checkDate"
                            id="trip-start-date"
                            hide-details
                            ></v-text-field>
                          </template>
                          <v-date-picker
                          :nudge-bottom="0"
                          v-model="date"
                          @input="dateMenu = false"
                          :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col class="pa-1" cols="12" md="2">
                        <v-btn @click="filterTrips" block elevation="0" color="white primary--text" height="48">Search</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <v-container>
            <div class="text-center">
                <v-progress-circular
                v-if="loading"
                :size="100"
                color="primary"
                class="my-10"
                indeterminate
                ></v-progress-circular>
            </div>

            <v-row v-if="!loading && displayingTrips.length" class="my-10" no-gutters>
                <template v-if="displayingTrips.length">
                    <v-col cols="12" sm="6" md="4" xl="3" v-for="item in displayingTrips" :key="item.id" class="pa-3">
                        <trip-card :trip="item"></trip-card>
                    </v-col>
                    <v-col cols="12">
                        <v-btn v-if="displayingTrips.length !== trips.length" elevation="0" @click="displayingTrips = trips" color="primary" x-large class="rounded-lg d-block mx-auto">show all</v-btn>
                    </v-col>
                </template>
                <p class="mx-auto text-h5 my-10" v-else>No trips!</p>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import user from '@/web_services/user'
import tripCard from '@/components/vendors/tripCard'

export default {
  components: {
    tripCard
  },
  data () {
    return {
      trips: [],
      displayingTrips: [],
      loading: true,
      dateMenu: false,
      date: '',
      city: ''
    }
  },
  methods: {
    setPlace (place) {
      this.city = place.formatted_address
    },
    checkDate () {
      const enteredDate = document.getElementById('trip-start-date').value
      if (!enteredDate) this.date = ''
    },
    filterTrips () {
      if (this.city || this.date) {
        this.displayingTrips = []
        this.trips.forEach(item => {
          if (!item.city) item.city = ''
          if ((item.city.includes(this.city) || !this.city) && (item.date === this.date || !this.date)) this.displayingTrips.push(item)
        })
      } else {
        this.displayingTrips = this.trips
      }
    }
  },
  mounted () {
    const google = document.getElementsByClassName('google-ac')[0]
    google.addEventListener('change', () => {
      if (!google.value) this.city = ''
    })
  },
  created () {
    user.activeTrips().then(response => {
      this.trips = response.data.data
      this.displayingTrips = response.data.data
    }).catch(() => {}).finally(() => { this.loading = false })
  }
}
</script>

<style>
  .background {
    height: auto;
    background: linear-gradient(#ff8f95d3, #ff8f95d3), url('../../assets/images/sea.jpg');
    background-color: #333;
    background-size: 100% 100%;
    background-position: center center;
  }
  .google-ac {
    outline: none;
    border: none;
    background: #fff;
    width: 100%;
    padding: 8px;
    margin-bottom: 8px;
    height: 50px;
  }
</style>
